<template>
  <div class="content-area shuttle-form">
    <hero-bar>
      {{ pageTitle }}

      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{'is-loading':isLoading}"
        @click="saveDetails"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component>
        <b-field label="Güzergah Adı" horizontal>
          <b-input
            v-model="form.name"
            placeholder="Güzergah Adı"
            required
            class="is-fullwidth"
          />
        </b-field>

        <b-field label="Tipi" horizontal>
          <b-select
            v-model="form.type"
            placeholder="Seçiniz"
            expanded
          >
            <option value="enter">Giriş</option>
            <option value="exit">Çıkış</option>
          </b-select>
        </b-field>

        <b-field label="Başlangıç & Bitiş Tarihi" horizontal>
          <b-datepicker
            v-model="form.startDate"
            placeholder="Başlangıç Tarihi"
            icon="calendar-today"
            :month-names="$t('dateTimePicker.monthNames')"
            :day-names="$t('dateTimePicker.dayNames')"
            trap-focus
          />
          <b-datepicker
            v-model="form.endDate"
            :first-day-of-week="1"
            :month-names="$t('dateTimePicker.monthNames')"
            :day-names="$t('dateTimePicker.dayNames')"
            :min-date="form.startDate"
            placeholder="Bitiş Tarihi"
            icon="calendar-today"
            trap-focus
          />
        </b-field>

        <b-field label="Saat" horizontal>
          <b-timepicker
            v-model="form.time"
            placeholder="Seçiniz"
            icon="clock"
            :incrementMinutes="5"
          />
        </b-field>

        <b-field label="Ortalama KM." horizontal>
          <b-input
            v-model="form.averageKm"
            v-cleave="masks.decimal"
            placeholder="Ortalama KM."
            name="average_km"
          />
        </b-field>

        <b-field label="Tutar" horizontal>
          <b-input
            ref="amount"
            v-model="form.amount"
            v-cleave="masks.decimal"
            placeholder="Tutar (KM Başına)"
            name="amount"
          />
        </b-field>

        <b-field label="Süre Kısıtı" class="mb-4" horizontal>
          <b-timepicker
            v-model="form.time_constraint"
            placeholder="Seçiniz"
            icon="clock"
            locale="tr-TR"
            hour-format="24"
            :incrementMinutes="5"
          />
        </b-field>

        <b-field label="Otomatik Sürücü/Araç Ata" horizontal>
          <b-checkbox v-model="form.auto_assign_car_and_driver" />
        </b-field>

        <b-field
          v-if="form.auto_assign_car_and_driver"
          label="Otomatik Atama Süresi"
          message="Bu alana girilen süreden önce otomatik olarak sürücü ve araç ataması yapılacaktır. Örneğin, shuttle akşam 18:00’de kalkacaksa ve bu alana 03:00 saat girilirse, otomatik atama 15:00’de yapılacaktır."
          horizontal
        >
          <b-timepicker
            v-model="form.auto_assign_time"
            placeholder="Seçiniz"
            icon="clock"
            hour-format="24"
            :incrementMinutes="30"
          />
        </b-field>

        <b-field
          v-if="form.auto_assign_car_and_driver"
          label="Otomatik Atama Maksimum Gün"
          horizontal
        >
          <b-input
            v-model="form.auto_assign_max_days"
            type="number"
            min="1"
            placeholder="Maksimum Gün Sayısı"
          />
        </b-field>

        <b-field label="Açıklama" horizontal>
          <b-input
            v-model="form.description"
            placeholder="Açıklama"
            name="description"
            required
          />
        </b-field>

        <b-field label="Konum" horizontal>
          <gmap-places
            :place.sync="form.startLocation"
            :adress="(form.startLocation.formatted_address) ? form.startLocation.formatted_address : ''"
            placeholder="Konum Seçin"
          />
        </b-field>

        <b-field horizontal>
          <div v-if="form.startLocation" class="is-user-avatar has-text-centered is-center">
            <gmap-image
              v-if="form.startLocation.latlng"
              :latlng="form.startLocation.latlng"
              :size="[300, 300]"
            />
          </div>
        </b-field>
      </card-component>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
import Cleave from 'cleave.js'

export default {
  name: 'ShuttleCreateOrUpdate',
  title: ({ $i18n }) => $i18n.t('titles.newShuttlePlan'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    GmapPlaces: () => import('@/components/GMapPlacesInput'),
    GmapImage: () => import('@/components/GMapImage'),
  },
  directives: {
    cleave: {
      inserted: (el, binding) => {
        const input = el.querySelector('input')
        input.cleave = new Cleave(input, binding.value || {})
      },
      update: (el) => {
        const input = el.querySelector('input')
        const event = new Event('input', {bubbles: true});
        setTimeout(() => {
          input.value = input.cleave.properties.result
          input.dispatchEvent(event)
        }, 150);
      },
      unbind (el) {
        const input = el.querySelector('input')
        input.cleave.destroy()
      }
    }
   },
  data () {
    return {
      isLoading: false,
      pageTitle: null,
      form: {
        name: '',
        description: '',
        startLocation: {},
        type: null,
        startDate: null,
        endDate: null,
        time: null,
        time_constraint: moment({ hour: 3, minute: 0 }).toDate(),
        averageKm: null,
        amount: null,
        auto_assign_car_and_driver: false,
        auto_assign_time: null,
        auto_assign_max_days: null,
      },
      masks: {
        decimal: {
          numeral: true,
          numeralDecimalMark: ',',
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
        },
      }
    }
  },
  computed: {
    isCreate () {
      return this.$route.name === 'shuttles.create'
    },
    id () {
      return this.$route.params.id;
    },
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  mounted () {
    this.$store.commit('setAsideActiveMenuKey', 'shuttles.index')

    if (this.isCreate) {
      this.pageTitle = this.$t('shuttlePlanCreate')
    } else {
      this.pageTitle = this.$t('shuttlePlanEdit')

      this.$nextTick(() => {
        this.getPlan();
      });
    }

    document.title = this.pageTitle
  },
  methods: {
    saveDetails () {
      let startAddress = this.form.startLocation.formatted_address

      if (!this.$_.isUndefined(this.form.startLocation.name)) {
        startAddress = `${this.form.startLocation.name} - ${startAddress}`
      }

      const data = {
        name: this.form.name,
        description: this.form.description,
        start_address: startAddress,
        start_location: this.form.startLocation.latlng,
        start_date: moment(this.form.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.form.endDate).format('YYYY-MM-DD'),
        time: moment(this.form.time).format('HH:mm'),
        time_constraint: moment(this.form.time_constraint).format('HH:mm'),
        type: this.form.type,
        average_km: this.form.averageKm,
        amount: this.form.amount,
        auto_assign_car_and_driver: this.form.auto_assign_car_and_driver,
        auto_assign_time: moment(this.form.auto_assign_time).isValid() ? moment(this.form.auto_assign_time).format('HH:mm') : null,
        auto_assign_max_days: this.form.auto_assign_max_days,
      };

      let url = 'shuttles/' + this.id
      let method = 'PUT'

      if (this.isCreate) {
        url = 'shuttle-create'
        method = 'POST'
      }

      this.isLoading = true

      this.$http({ url, method, data })
        .then(r => {
          if (r.data.success) {
            this.$buefy.toast.open({
              message: this.$t('shuttlePlanCreated'),
              type: 'is-success',
              duration: 5000,
            })

            this.$router.push({ name: 'shuttles.index' })
          }
        })
        .catch(e => {
          const errors = this.parseError(e)

          this.$buefy.toast.open({
            message: this.$t('error') + ': <br>' + errors,
            type: 'is-warning',
            duration: 5000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getPlan () {
      this.showSpinner()
      this.$http.get(`shuttles/${this.id}`)
        .then(({ data }) => {
          this.form.name = data.name
          this.form.description = data.description
          this.form.time = moment(data.time, 'hh:mm').toDate()
          this.form.time_constraint = moment(data.time_constraint, 'hh:mm').toDate()
          this.form.type = data.type
          this.form.startDate = moment(data.start_date).toDate()
          this.form.endDate = moment(data.end_date).toDate()
          this.form.auto_assign_car_and_driver = data.auto_assign_car_and_driver
          this.form.auto_assign_time = data.auto_assign_time ? moment(data.auto_assign_time, 'hh:mm').toDate() : null
          this.form.auto_assign_max_days = data.auto_assign_max_days

          document.querySelector('input[name=\'amount\']').cleave.setRawValue(data.amount);
          document.querySelector('input[name=\'average_km\']').cleave.setRawValue(data.average_km);

          this.form.startLocation.formatted_address = data.start_address
          this.form.startLocation.latlng = data.start_location
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t('error'),
            type: 'is-warning',
            duration: 5000
          });
          this.$router.push({ name:'shuttles.index' });
        })
        .finally(() => {
          this.hideSpinner()
        });
    }
  }
}
</script>
